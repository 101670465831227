<script>
import simplebar from "simplebar-vue";
import appConfig from "@/app.config";

import SideNav from "./side-nav";
import { layoutComputed } from "@/state/helpers";

/**
 * Sidebar component
 */
export default {
  components: { simplebar, SideNav },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      version : appConfig.version,
      menuItems: []
    };
  },
  methods: {
    onRoutechange(newVal) {
      
      this.$parent.changeLogo(newVal);

      setTimeout(() => {
        if(document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop =
            currentPosition + 300;
        }
      }, 300);
    },
    reloadMenu(){
      
    },
    getMenuItemsByRoleAndProducts() {
    
    let menu = []; 

    const role = JSON.parse(localStorage.getItem('auth.currentUser'))?.user?.role;

    const app = localStorage.getItem('app');

    if(!role)
        return;

    //const products = JSON.parse(localStorage.getItem('auth.currentUser')).user.company.products;
    
    if(role == 'superadmin'){

        menu.push({
            id: 1,
            label: "menu.general",
            isTitle: true
        }),

        menu.push({
            id: 4,
            label: "menu.general_tenants",
            icon: "bx-buildings",
            link: "/tenants",
        });
    }

    /*if (role == 'admin'){
        menu.push({
            id: 1,
            label: "menu.general",
            isTitle: true
        }),

        menu.push({
            id: 233,
            label: "Dashboard",
            icon: "bx-pie-chart-alt-2",
            link: "/",
        });

        menu.push({
            id: 234,
            label: "Analytics",
            icon: "bx bx-line-chart",
            subItems:[
              {
                  id: 4,
                  label: "menu.personalizations",
                  link: "/personal-analyze",
              },
              {
                  id: 3,
                  label: "menu.recommendations",
                  link: "/reco-analyze",
                },
            ]
        });
        
        menu.push({
            id: 6,
            label: "menu.general_users",
            icon: "bx-user",
            link: "/users",
        });

        menu.push({
            id: 36,
            label: "Items",
            icon: "bx bx-purchase-tag-alt",
            link: "/items",
        });

        menu.push({
            id: 60,
            label: "menu.customers",
            icon: "bx bx-id-card",
            link: "/customers",
        });
        menu.push({
            id: 61,
            label: "menu.integrations",
            icon: "bx bx-link",
            link: "/integrations",
        });

    }*/
    
    if((app && app == 'live') || role =='host'){
      if(role == 'host')
        menu.push({
            id: 10,
            label: "Go Live-Shopping",
            isTitle: true
        })
    

    if(role== 'admin'){
        menu.push(
            {
                id: 26,
                label: "menu.live_analyze",
                icon: "bx-bar-chart-alt-2",
                subItems:[
                    {
                        id: 3,
                        label: "menu.live_metrics",
                        icon: "bx-bar-chart-alt-2",
                        link: "/metrics",
                    },
                    {
                        id: 27,
                        label: "menu.live_calls",
                        icon: "bx-bar-chart-alt-2",
                        link: "/events-list",
                    }
                ]
            }
        );
        
        menu.push({
            id: 65,
            label: "Hosts",
            icon: "bx-group",
            link: "/hosts",
        });

        menu.push({
            id: 5,
            label: "menu.live_groups",
            icon: "bx bx-cube-alt",
            link: "/groups",
        });

        
        menu.push({
            id: 9,
            label: "menu.live_availability",
            icon: "bx bx-time-five",
            link: "/availability",
        });
        menu.push({
            id: 7,
            label: "menu.live_campaigns",
            icon: "bx bx-briefcase-alt-2",
            link: "/campaigns",
        });
        menu.push({
            id: 17,
            label: "menu.live_videos",
            icon: "bx bx-slideshow",
            link: "/videos",
            badge: {
                variant: "info",
                text: "Beta"
            }
        });
        menu.push({
            id: 8,
            label: "menu.live_events",
            icon: "bx bx-calendar-event",
            link: "/events",
        });
    }
    else if(role =='host'){

        menu.push({
            id:33,
            label:"menu.live_next_events",
            icon: "bx bx-task",
            link: "/next-events"
        });

        menu.push({
            id: 18,
            label: "menu.live_events",
            icon: "bx bx-calendar-event",
            link: "/events",
        });

        menu.push({
            id: 17,
            label: "menu.live_campaigns",
            icon: "bx bx-briefcase-alt-2",
            link: "/campaigns",
        });
    }
}
    if(app && app == 'search'){

        if (role == 'admin'){
            menu.push({
                id: 11,
                label: "Go Search",
                isTitle: true
            })

            menu.push({
                id: 20,
                label: "menu.search_config",
                icon: "bx bx-wrench",
                link: "/search-project",
            })

            menu.push({
                id: 21,
                label: "menu.search_analyze",
                icon: "bx bx-bar-chart",
                subItems:[
                    {
                        id: 34,
                        label: "menu.search_analyze_metrics",
                        icon: "bx-bar-chart-alt-2",
                        link: "/search-analyze",
                    },
                    {
                        id: 35,
                        label: "menu.search_analyze_clusters",
                        icon: "bx-bar-chart-alt-2",
                        link: "/search-clusters",
                    },
                    {
                        id: 37,
                        label: "menu.search_list",
                        icon: "bx-bar-chart-alt-2",
                        link: "/search-list",
                    },
                ]
            })
    }
    }

    if(app && app == 'reco'){
        if (role == 'admin'){
        
            menu.push({
                id: 23,
                label: "Go Recommendation",
                isTitle: true
            })

            menu.push({
                id: 24,
                label: "menu.reco_config",
                icon: "bx bx-wrench",
                link: "/reco-project",
            })

            menu.push({
                id: 25,
                label: "menu.reco_analyze",
                icon: "bx bx-bar-chart",
                link: "/reco-analyze",
            })
        }        
    }

    /*if(!app || app == 'personal'){
        if (role == 'admin'){
        
            menu.push({
                id: 53,
                label: "Personalization",
                isTitle: true
            })

            menu.push({
                id: 55,
                label: "menu.catalogs",
                icon: "bx bx-package",
                subItems:[
                  {
                    id: 63,
                    label: "menu.interactions",
                    icon: "",
                    link: "/interactions",
                  },
                  {
                    id: 64,
                    label: "menu.goals",
                    icon: "",
                    link: "/goals",
                  },
                  {
                      id: 56,
                      label: "menu.catalogs_templates",
                      icon: "",
                      link: "/templates",
                  }
                ]
            });
            menu.push({
                id: 62,
                label: "menu.templates_store",
                icon: "bx bx-store",
                link: "/templates-store",
            });  
            
            menu.push({
                id: 55,
                label: "menu.segmentation",
                icon: "bx bx-layout",
                subItems:[
                  {
                    id: 38,
                    label: "RFM",
                    icon: "bx bx-layout",
                    link: "/segmentation",
                  },
                  {
                      id: 54,
                      label: "menu.audiences",
                      icon: "bx bx-group",
                      link: "/audiences",
                  }
                ]
            });
            
            menu.push({
                id: 57,
                label: "menu.personalizations",
                icon: "bx bxs-magic-wand",
                link: "/personalizations",
            }),
            menu.push({
                id: 56,
                label: "menu.journeys",
                icon: "bx bx-directions",
                link: "/journeys",
            })

            

            
            menu.push({
              id: 58,
              label: "Broadcasts",
              icon: "bx bx-paper-plane",
              link: "/broadcasts",
            })
            

            menu.push({
                id: 69,
                label: "Go Live Shopping",
                image: require("@/assets/images/go_live_shop_small.svg"),
                subItems:[
                {
                      id: 26,
                      label: "menu.live_analyze",
                      icon: "bx-bar-chart-alt-2",
                      parentId: 69,
                      subItems:[
                          {
                              id: 3,
                              label: "menu.live_metrics",
                              icon: "bx-bar-chart-alt-2",
                              link: "/metrics",
                              parentId: 26,
                          },
                          {
                              id: 27,
                              label: "menu.live_calls",
                              icon: "bx-bar-chart-alt-2",
                              link: "/events-list",
                              parentId: 26,
                          }
                      ]
                  },
                  {
                    id: 65,
                    label: "Hosts",
                    icon: "bx-group",
                    link: "/hosts",
                  },
                  {
                    id: 5,
                    label: "menu.live_groups",
                    icon: "bx bx-cube-alt",
                    link: "/groups",
                  },
                  {
                    id: 9,
                    label: "menu.live_availability",
                    icon: "bx bx-time-five",
                    link: "/availability",
                  },
                  {
                    id: 7,
                    label: "menu.live_campaigns",
                    icon: "bx bx-briefcase-alt-2",
                    link: "/campaigns",
                  },
                  {
                    id: 17,
                    label: "menu.live_videos",
                    icon: "bx bx-slideshow",
                    link: "/videos",
                    badge: {
                        variant: "info",
                        text: "Beta"
                    }
                  },
                  {
                    id: 8,
                    label: "menu.live_events",
                    icon: "bx bx-calendar-event",
                    link: "/events",
                  }
                ]
            });  

            menu.push({
                id: 70,
                label: "Go Search",
                image: require("@/assets/images/go_search_small.svg"),
                subItems:[
                  {
                      id: 34,
                      label: "menu.search_analyze_metrics",
                      icon: "bx-bar-chart-alt-2",
                      link: "/search-analyze",
                  },
                  {
                      id: 35,
                      label: "menu.search_analyze_clusters",
                      icon: "bx-bar-chart-alt-2",
                      link: "/search-clusters",
                  },
                  {
                      id: 37,
                      label: "menu.search_list",
                      icon: "bx-bar-chart-alt-2",
                      link: "/search-list",
                  }  
                ]
                });
                
        }        
    }*/

    if(!app || app == 'personal'){
        if (role == 'admin'){
          
          menu.push({
            id: 233,
            label: "Dashboard",
            icon: "bx bx-home-circle",
            link: "/",
        });

        menu.push({
            id: 234,
            label: "Analytics",
            icon: "bx bx-line-chart",
            subItems:[
              {
                  id: 4,
                  label: "menu.personalizations",
                  link: "/personal-analyze",
              },
              {
                id: 3,
                label: "menu.recommendations",
                link: "/reco-analyze",
              },
              {
                id: 42,
                label: "menu.broadcasts",
                link: "/broadcast-analyze",
              },
              {
                id: 43,
                label: "menu.journeys",
                link: "/journey-analyze",
              },
              {
                id: 40,
                label: "menu.usage",
                link: "/usage",
              }
            ]
        });
        
        menu.push({
            id: 62,
            label: "menu.templates_store",
            icon: "bx bx-store",
            link: "/templates-store",
        });  
        
        menu.push({
            id: 55,
            label: "menu.segmentation",
            icon: "bx bx-layout",
            subItems:[
              {
                id: 155,
                label: "menu.contact_lists",
                icon: "bx bx-group",
                link: "/contact-lists",
              },
              {
                  id: 54,
                  label: "menu.audiences",
                  icon: "bx bx-group",
                  link: "/audiences",
              },
              {
                id: 154,
                label: "menu.audience_explorer",
                icon: "bx bx-group",
                link: "/audiences-explorer",
              }
            ]
        });

        menu.push({
                id: 57,
                label: "menu.personalizations",
                icon: "bx bxs-magic-wand",
                link: "/personalizations",
            })
            
            menu.push({
                id: 56,
                label: "menu.journeys",
                icon: "bx bx-directions",
                link: "/journeys",
            })

            menu.push({
              id: 58,
              label: "Broadcasts",
              icon: "bx bx-paper-plane",
              link: "/broadcasts",
            })

            menu.push({
                id: 60,
                label: "menu.customers",
                icon: "bx bx-id-card",
                link: "/customers",
            });

            menu.push({
                id: 534,
                label: "Addons",
                isTitle: true
            })

            menu.push({
                id: 69,
                label: "Go Live Shopping",
                image: require("@/assets/images/go_live_shop_small.svg"),
                subItems:[
                  /*{
                      id: 26,
                      label: "menu.live_analyze",
                      icon: "bx-bar-chart-alt-2",
                      parentId: 69,
                      subItems:[
                          {
                              id: 3,
                              label: "menu.live_metrics",
                              icon: "bx-bar-chart-alt-2",
                              link: "/metrics",
                              parentId: 26,
                          },
                          {
                              id: 27,
                              label: "menu.live_calls",
                              icon: "bx-bar-chart-alt-2",
                              link: "/events-list",
                              parentId: 26,
                          }
                      ]
                  },*/
                  {
                    id: 72,
                    label: "Analytics",
                    icon: "bx-group",
                    link: "/live-shopping-analyze",
                    parentId: 69,
                  },
                  {
                    id: 65,
                    label: "Hosts",
                    icon: "bx-group",
                    link: "/hosts",
                  },
                  {
                    id: 5,
                    label: "menu.live_groups",
                    icon: "bx bx-cube-alt",
                    link: "/groups",
                  },
                  {
                    id: 9,
                    label: "menu.live_availability",
                    icon: "bx bx-time-five",
                    link: "/availability",
                  },
                  {
                    id: 7,
                    label: "menu.live_campaigns",
                    icon: "bx bx-briefcase-alt-2",
                    link: "/campaigns",
                  },
                  {
                    id: 71,
                    label: "menu.live_calls",
                    icon: "bx-group",
                    link: "/events-list",
                    parentId: 69,
                  },
                  /*{
                    id: 17,
                    label: "menu.live_videos",
                    icon: "bx bx-slideshow",
                    link: "/videos",
                    badge: {
                        variant: "info",
                        text: "Beta"
                    }
                  },*/
                  {
                    id: 8,
                    label: "menu.live_events",
                    icon: "bx bx-calendar-event",
                    link: "/events",
                  },
                  {
                    id:17,
                    label: "menu.live_videos",
                    icon: "bx bx-calendar-event",
                    link: "/videos",
                  }
                ]
            });  

            menu.push({
                id: 70,
                label: "Go Search",
                image: require("@/assets/images/go_search_small.svg"),
                subItems:[
                  {
                      id: 34,
                      label: "menu.search_analyze_metrics",
                      icon: "bx-bar-chart-alt-2",
                      link: "/search-analyze",
                  },
                  {
                      id: 37,
                      label: "menu.search_list",
                      icon: "bx-bar-chart-alt-2",
                      link: "/search-list",
                  },
                  {
                      id: 36,
                      label: "menu.search_settings",
                      icon: "bx-bar-chart-alt-2",
                      link: "/search-settings",
                  }   
                ]
                });
              if(window.config.env == 'dev'){
              menu.push({
                id: 71,
                label: "Go Loyalty",
                image: require("@/assets/images/go_loyalty_small.svg"),
                subItems:[
                {
                      id: 32,
                      label: "Analytics",
                      icon: "bx-bar-chart-alt-2",
                      link: "/loyalty-dashboard",
                  },
                  {
                    id: 235,
                    label: "Programas de Lealtad",
                    icon: "bx-bar-chart-alt-2",
                    link: "/loyalty-programs",
                  },
                  {
                      id: 33,
                      label: "Miembros",
                      icon: "bx-bar-chart-alt-2",
                      link: "/loyalty-members",
                  },
                  {
                      id: 34,
                      label: "Niveles",
                      icon: "bx-bar-chart-alt-2",
                      link: "/loyalty-levels",
                  },
                  {
                      id: 35,
                      label: "Campañas",
                      icon: "bx-bar-chart-alt-2",
                      link: "/loyalty-campaigns",
                  },
                  {
                      id: 36,
                      label: "Recompensas",
                      icon: "bx-bar-chart-alt-2",
                      link: "/loyalty-rewards",
                  },
                  {
                      id: 37,
                      label: "Canjes",
                      icon: "bx-bar-chart-alt-2",
                      link: "/loyalty-fulfillments",
                  },
                ]
                });
            }

            
            menu.push({
              id: 72,
              label: "Go Personal Shopper",
              image: require("@/assets/images/go_personal_shopper_small.svg"),
              subItems:[
              {
                    id: 1,
                    label: "Personal Shoppers",
                    icon: "bx-bar-chart-alt-2",
                    link: "/personal-shoppers",
                }
              ]
            })
            
            if(window.config.local){
                menu.filter(m=>m.id== 72)[0].subItems.push({
                  id: 2,
                  label: "Analytics",
                  icon: "bx-bar-chart-alt-2",
                  link: "/personal-shopper-analyze"
                })
              }

            menu.push({
              id: 533,
              label: "General",
              isTitle: true
            }) 
            
            menu.push({
              id: 61,
              label: "menu.integrations",
              icon: "bx bx-link",
              link: "/integrations",
            });

            menu.push({
                id: 36,
                label: "Items",
                icon: "bx bx-purchase-tag-alt",
                link: "/items",
            });

            menu.push({
              id: 73,
              label: "menu.catalogs",
              icon: "bx bx-package",
              subItems:[
              {
                  id: 62,
                  label: "menu.properties",
                  icon: "",
                  link: "/properties",
                },
                {
                  id: 63,
                  label: "menu.interactions",
                  icon: "",
                  link: "/interactions",
                },
                {
                  id: 64,
                  label: "menu.goals",
                  icon: "",
                  link: "/goals",
                },
                {
                  id: 65,
                  label: "menu.custom_strategies",
                  icon: "",
                  link: "/custom-strategies",
                },
                {
                  id: 66,
                  label: "menu.email_components",
                  icon: "",
                  link: "/email-components",
                },
                {
                    id: 56,
                    label: "menu.catalogs_templates",
                    icon: "",
                    link: "/templates",
                }
              ]
            });

            if(window.config.local){
              menu.filter(m=>m.id== 73)[0].subItems.push({
                  id: 2,
                  label: "GP Templates Store",
                  icon: "bx-bar-chart-alt-2",
                  link: "/gp-templates-store"
                })
            }
            
            menu.push({
                id: 6,
                label: "menu.general_users",
                icon: "bx-user",
                link: "/users",
            });
        }        
    }

    return menu;
}
  },
  mounted() {
    this.menuItems = this.getMenuItemsByRoleAndProducts();
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "dark");
              document.body.removeAttribute("data-sidebar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "small");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "icon":
              document.body.setAttribute("data-keep-enlarged", "true");
              document.body.classList.add("vertical-collpsed");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "colored":
              document.body.setAttribute("data-sidebar", "colored");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-sidebar-size");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              document.body.removeAttribute("data-layout-scrollable");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              document.body.removeAttribute("data-layout-scrollable");
              break;
            case "scrollable":
              document.body.setAttribute("data-layout-scrollable", "true");
              document.body.removeAttribute("data-layout-mode");
              document.body.removeAttribute("data-layout-size")
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <!-- original => v-if="!isCondensed" -->
  <div class="vertical-menu">
    <simplebar
      :settings="settings"
      class="h-100"
      ref="currentMenu"
      id="my-element"
    >
      <SideNav ref="sideNavComponent" :items="menuItems" />
    </simplebar>

    <!-- <simplebar v-else class="h-100">
      <SideNav />
    </simplebar> -->
    
  </div>
  <!-- Left Sidebar End -->
</template>

<style scoped>
.version-label {
    position: absolute;
    bottom: 0px;
    left: 20px;
    color: #ffffff;
}
</style>
